// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import $ from 'jquery'
import "bootstrap"

// Signature Pad
import SignaturePad from 'signature_pad/dist/signature_pad.min.js';

require('datatables.net-bs4')
require('packs/datatables')
require('packs/intl_tel_input')

require("stylesheets/application.scss")

Rails.start()
Turbolinks.start()
ActiveStorage.start()

global.$ = jQuery;
global.toastr = require("toastr")

// Flackpickr
import flatpickr from "flatpickr"
require("flatpickr/dist/flatpickr.css")


$(document).on('turbolinks:load', function() {
    // Signature Pad
  var signaturePadCanvas = document.getElementById('signature-pad')

  if (signaturePadCanvas) {
    var signaturePad = new SignaturePad(signaturePadCanvas, {
      backgroundColor: 'rgba(255, 255, 255, 0.2)',
      penColor: 'rgb(0, 0, 0)'
    });

    var cancelButton = document.getElementById('signature_pad_clear');
    cancelButton.addEventListener('click', function (event) {
      signaturePad.clear();
    });

    var saveButton = document.getElementById('signature_pad_save');

    saveButton.addEventListener('click', function (event) {
      if (!signaturePad.isEmpty()){
        $('#user_signature').val(signaturePad.toDataURL());
      }
    });
  }

  flatpickr("[data-behavior='appointment_datepicker']", {
    altInput: true,
    altFormat: "F j, Y",
    dataFormot: "Y-m-d",
    minDate: "today",
    maxDate: new Date().fp_incr(14)
  })

  // Handle adding and removing of appointment times
  $('form').on('click', '.remove_appointment_time', function(event) {
    let field_parent = this.closest('.card-container');
    let delete_field = field_parent ? field_parent.querySelector('input[type="hidden"]') : null;

    if (delete_field) {
      delete_field.value = 1;
      field_parent.style.display = 'none';
    }
    
    return event.preventDefault();
  });

  $('form').on('click', '.add_appointment_times', function(event) {
    var regexp, time;
    time = new Date().getTime();
    regexp = new RegExp($(this).data('id'), 'g');
    $('.new_appointment_time').append($(this).data('fields').replace(regexp, time));
    event.preventDefault();
  });

  // Handle appointment selection
  var available_times = $('#new_appointment_time').html();
  var available_times_tag = $('#new_appointment_time');

  var days = ['Monday','Tuesday','Wednesday','Thursday','Friday','Saturday', 'Sunday'];

  if($("#appointment-date-selector").val() != "") {
    var operation_day = days[new Date($("#appointment-date-selector").val()).getDay()];
    var options = $(available_times).filter("optgroup[label='" + operation_day + "']").html();

    if (options) {
      $('.appointment_time_alert').addClass("d-none");
      available_times_tag.parent().removeClass("d-none");
      available_times_tag.html(options);
    } else {
      $('.appointment_time_alert').removeClass("d-none");
      available_times_tag.parent().addClass("d-none");
      available_times_tag.empty();
    }
  }

  $('#appointment-date-selector').change(function() {
    available_times_tag.selectedIndex = -1;
    var operation_day = days[new Date($(this).val()).getDay()];
    var options = $(available_times).filter("optgroup[label='" + operation_day + "']").html();


    if (options) {
      $('.appointment_time_alert').addClass("d-none");
      available_times_tag.parent().removeClass("d-none");
      available_times_tag.html(options);
    } else {
      $('.appointment_time_alert').removeClass("d-none");
      available_times_tag.parent().addClass("d-none");
      available_times_tag.empty();
    }
  })

  $("#operation_day_status").click(function () {
    if (this.options[1].selected == true) {
      $('#operation_day_appointments_allowed').attr("disabled", true);
    }
  });
});