require("intl-tel-input/build/js/utils")

// Internation Tel 
import intlTelInput from 'intl-tel-input';

document.addEventListener("turbolinks:load", () => {
  var phone_input = document.getElementById("phone");

  if (phone_input) {
    //Initialize intltel
    var phone_input_iti = intlTelInput(phone_input, {
      initialCountry: 'us',
      separateDialCode: true
    });

    var reset = function() {
      phone_input.classList.remove("is-valid");
      phone_input.classList.remove("is-invalid"); 
    };

    // on blur: validate
    phone_input.addEventListener('blur', function() {
      reset();
      if (phone_input.value.trim()) {
        if (phone_input_iti.isValidNumber()) {
          phone_input.classList.add("is-valid");
        } else {
          phone_input.classList.add("is-invalid");
        }
      }
    });

    // on keyup / change flag: reset
    phone_input.addEventListener('change', reset);
    phone_input.addEventListener('keyup', reset);
  }
});