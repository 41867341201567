// Users Datatable
document.addEventListener("turbolinks:load", () => {
  if($('[id^=users-datatable]').length == 1) {
    $('#users-datatable').dataTable({
      "processing": true,
      "serverSide": false,
      "ajax": {
        "url": $('#users-datatable').data('source'),
        "type": 'POST'
      },
      "pagingType": "full_numbers",
      "columns": [
        { "data": "first_name" },
        { "data": "last_name" },
        { "data": "email" },
        { "data": "location" },
        { "data": "account_status" },
        { "data": "access_level" },
        { "data": "actions", searchable: false, orderable: false },
      ],
       "dom": "<''<'card-body py-3 d-flex'<'text-muted'l><'ms-auto text-muted'f>>" +
               "<'table-responsive'<'col-sm-12'tr>>" +
               "<'card-footer d-flex align-items-center'<'m-0 text-muted'i><'pagination m-0 ms-auto'p>>",
        "order": [[3, "asc"]],
        language: {
          paginate: {
            next: 'next <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><polyline points="9 6 15 12 9 18"></polyline></svg>',
            previous: '<svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><polyline points="15 6 9 12 15 18"></polyline></svg> prev'
          }
        }
    });

    $('div.dataTables_length select').removeClass( "custom-select custom-select-sm form-control form-control-sm");
    $('div.dataTables_length select').addClass( "mx-2 d-inline-block datatables-form-select");
  }
});

//Appointment Tests Datatable
document.addEventListener("turbolinks:load", () => {
  if($('[id^=appointment-test-datatable]').length == 1) {
    $('#appointment-test-datatable').dataTable({
      "processing": true,
      "serverSide": true,
      "ajax": {
        "url": $('#appointment-test-datatable').data('source'),
        "type": 'POST'
      },
      "pagingType": "full_numbers",
      "columns": [
        { "data": "appointment_number" },
        { "data": "first_name" },
        { "data": "speciment_number" },
        { "data": "test_type" },
        { "data": "result_date" },
        { "data": "result" },
        { "data": "actions", searchable: false, orderable: false },
      ],
       "dom": "<''<'card-body py-3 d-flex'<'text-muted'l><'ms-auto text-muted'f>>" +
               "<'table-responsive'<'col-sm-12'tr>>" +
               "<'card-footer d-flex align-items-center'<'m-0 text-muted'i><'pagination m-0 ms-auto'p>>",
        "order": [[2, "desc"]],
        language: {
          paginate: {
            next: 'next <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><polyline points="9 6 15 12 9 18"></polyline></svg>',
            previous: '<svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><polyline points="15 6 9 12 15 18"></polyline></svg> prev'
          }
        }
    });

    $('div.dataTables_length select').removeClass( "custom-select custom-select-sm form-control form-control-sm");
    $('div.dataTables_length select').addClass( "mx-2 d-inline-block datatables-form-select");
  }
});

//Appointments Datatable
document.addEventListener("turbolinks:load", () => {
  if($('[id^=appointment-datatable]').length == 1) {
    $('#appointment-datatable').dataTable({
      "processing": true,
      "serverSide": true,
      "ajax": {
        "url": $('#appointment-datatable').data('source'),
        "type": 'POST'
      },
      "pagingType": "full_numbers",
      "columns": [
        { "data": "appointment_custom_datetime" },
        { "data": "booked_at" },
        { "data": "appointment_number" },
        { "data": "first_name" },
        { "data": "last_name" },
        { "data": "date" },
        { "data": "time" },
        { "data": "location" },
        { "data": "sample_status", searchable: false, orderable: false },
        { "data": "actions", searchable: false, orderable: false },
      ],
       "dom": "<''<'card-body py-3 d-flex'<'text-muted'l><'ms-auto text-muted'f>>" +
               "<'table-responsive'<'col-sm-12'tr>>" +
               "<'card-footer d-flex align-items-center'<'m-0 text-muted'i><'pagination m-0 ms-auto'p>>",
        "order": [[1, "desc"]],
        "columnDefs": [
          { className: "d-none", "targets": [ 0 ] }
        ],
        language: {
          paginate: {
            next: 'next <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><polyline points="9 6 15 12 9 18"></polyline></svg>',
            previous: '<svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><polyline points="15 6 9 12 15 18"></polyline></svg> prev'
          }
        }
    });

    $('div.dataTables_length select').removeClass( "custom-select custom-select-sm form-control form-control-sm");
    $('div.dataTables_length select').addClass( "mx-2 d-inline-block datatables-form-select");
  }
});

// Generic Datatable
document.addEventListener("turbolinks:load", () => {
  if($('[id^=dataTable]').length == 1) {
    $('#dataTable').DataTable( {
      "dom": "<''<'card-body border-bottom py-3 d-flex'<'text-muted'l><'ms-auto text-muted'f>>" +
             "<'table-responsive'<'col-sm-12'tr>>" +
             "<'card-footer d-flex align-items-center'<'m-0 text-muted'i><'pagination m-0 ms-auto'p>>",
      "order": [[0, "desc"]],
      language: {
        paginate: {
          next: 'next <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><polyline points="9 6 15 12 9 18"></polyline></svg>',
          previous: '<svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><polyline points="15 6 9 12 15 18"></polyline></svg> prev'
        }
      }
    });

    $('div.dataTables_length select').removeClass( "custom-select custom-select-sm form-control form-control-sm");
    $('div.dataTables_length select').addClass( "mx-2 d-inline-block datatables-form-select");
  }
});